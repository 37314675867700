<template>
    <main v-if="showData">
        <div class="container">
            <table>
                <thead>
                    <tr>
                        <th>Nome</th>
                        <th>Telefone</th>
                        <th>E-mail</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="user in users" v-bind:key="user">
                        <td>{{ user.name }}</td>
                        <td>{{ user.telephone }}</td>
                        <td>{{ user.email }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </main>
</template>

<script>
export default {
    name: "demonstrationEmail",
    props: {
        users: {
            type: Array,
            default: []
        }
    },
    computed: {
        showData() {
            return this.users.length > 0;
        },
    },
    data() {},
    mounted() {},
    methods: {}
}
</script>

<style lang="scss"></style>