<template>
  <main v-if="showData">
    <div class="about-orgnanization">
      <div class="about-orgnanization-info">
        <div class="about-orgnanization-info-orgimg">
          <img
            :src="
              organizacao && organizacao.photoPath
                ? organizacao.photoPath
                : getImageBaseUrl('img-example.png')
            "
            alt
          />
        </div>
        <div class="about-orgnanization-info-org">
          <h2>{{ $t("map.title") }}</h2>
          <h3>{{ organizacao.organizationInitiative }}</h3>
          <span>{{ organizacao.name }}</span>
          <p>{{ $t("organization.areas." + organizacao.segment + ".text") }}</p>
          <p>{{ organizacao.city }}, {{ organizacao.country }}</p>
        </div>
        <div class="about-orgnanization-info-mission">
          <h2>{{ $t("map.mission") }}</h2>
          <h4>{{ organizacao.mission }}</h4>
        </div>
        <div class="about-orgnanization-info-img">
          <img :src="getImageBaseUrl('card-icon1.png')" alt />
        </div>
      </div>
    </div>

    <div class="about-map">
      <div class="about-map-header">
        <div class="about-map-header-title">
          <h2>{{ $t("map.radar") }}</h2>
        </div>
        <div class="about-map-line">
          <div class="about-map-radar-graph">
            <div class="about-map-radar-cards">
              <div
                class="about-map-radar-card"
                v-for="dados in radar"
                v-bind:key="dados"
                :style="{
                  'background-color': dados.odsColor,
                }"
              >
                <div class="about-map-radar-text">
                  <div class="about-map-radar-text-ods-number">
                    <p>{{ dados.ods }}</p>
                  </div>
                  <div class="about-map-radar-text-ods-title">
                    <span>{{ $t("map.ods.ods" + dados.ods + ".name") }}</span>
                  </div>
                </div>
                <div class="about-map-radar-img">
                  <img
                    :src="getImageBaseUrl('ods-icon' + dados.ods + '.png')"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="about-map-radar-chart">
            <img
              :src="getUrlChartImage('ods')"
              class="img-chart"
              crossorigin="anonymous"
            />
            <canvas class="chart2"></canvas>
          </div>
        </div>
      </div>
    </div>

    <div class="about-radar">
      <div class="about-radar-ods">
        <div class="about-radar-ods-cards">
          <div
            class="about-radar-ods-card"
            v-for="dados in radar"
            v-bind:key="dados"
          >
            <div class="about-radar-ods-text">
              <img :src="getImageBaseUrl('ods-icon' + dados.ods + '.png')" />
              <span>{{ dados.ods }}</span>
              <h2>{{ $t("map.ods.ods" + dados.ods + ".name") }}</h2>
              <p>{{ dados.finalNote }}%</p>
            </div>
            <div
              :style="{
                width: dados.finalNote + '%',
                'background-color': '#e6e9ed',
                height: '5px',
              }"
            >
              <div
                class="about-radar-ods-bar"
                :style="{
                  'background-color': dados.odsColor,
                  width: dados.finalNote,
                }"
              ></div>
            </div>
            <div class="about-radar-ods-desc">
              <h2>{{ $t("map.ods.ods" + dados.ods + ".desc") }}</h2>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="about-map">
      <div class="about-map-header">
        <div class="about-map-header-title">
          <h2>{{ $t("header.esg") }}</h2>
        </div>
        <div class="about-map-line">
          <div class="about-map-radar-graph">
            <div class="about-map-radar-cards">
              <div
                class="about-map-radar-card"
                v-for="(axle, index) in esgLabels"
                v-bind:key="axle"
                :style="{
                  'background-color': esgColors[index],
                }"
              >
                <div class="about-map-radar-text">
                  <div class="about-map-radar-text-ods-title">
                    <span>{{ axle }}</span>
                  </div>
                </div>
                <div class="about-map-radar-img">
                  <img
                    :src="getImageBaseUrl(esgIcons[index] + '.png')"
                    width="50"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="about-map-radar-chart">
            <img  
              :src="getUrlChartImage('esg')"
              class="img-chart"
              crossorigin="anonymous"
            />-
            <canvas class="chart3"></canvas>
          </div>
        </div>
      </div>
    </div>

    <div class="about-radar">
      <div class="about-radar-ods">
        <div class="about-radar-ods-cards">
          <div
            class="about-radar-ods-card"
            v-for="(axle, index) in esgLabels"
            v-bind:key="axle"
          >
            <div class="about-radar-ods-text" :style="{'background-color': esgColors[index]}">
              <img
                    :src="getImageBaseUrl(esgIcons[index] + '.png')"
                    width="80"
                  />
              <h2>{{ axle }}</h2>
              <p>{{ esgDataIndividual[index].toFixed(1).toString().replace(".", ",") }}%</p>
            </div>
            <div class="about-radar-ods-desc">
              <p v-html="$t('esg.' + axle + '.description')"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  </main>
</template>

<script>
import "../javascript/chart.bundle.min.js";
import "../javascript/chartjs-plugin-datalabels";
import "../javascript/chartjs-plugin-labels";
export default {
  name: "mapEmail",
  props: {
    organizacao: {
      type: Object,
      default: {},
    },
    radar: {},
    esgData: {},
    esgDataIndividual: {},
    series: {
      type: Array,
      default: [],
    },
    colors: {
      type: Array,
      default: [],
    },
  },
  computed: {
    showData() {
      return (
        this.colors.length > 0 && this.series.length > 0 && this.organizacao
      );
    },
  },
  data() {
    return {
      chart: null,
      chart_esg: null,
      ods_onu_links: null,
      ods_number_colors: [
        { ods: 1, color: "#E5243B" },
        { ods: 2, color: "#DDA63A" },
        { ods: 3, color: "#4C9F38" },
        { ods: 4, color: "#C5192D" },
        { ods: 5, color: "#FF3A21" },
        { ods: 6, color: "#26BDE2" },
        { ods: 7, color: "#FCC30B" },
        { ods: 8, color: "#A21942" },
        { ods: 9, color: "#FD6925" },
        { ods: 10, color: "#DD1367" },
        { ods: 11, color: "#FD9D24" },
        { ods: 12, color: "#BF8B2E" },
        { ods: 13, color: "#3F7E44" },
        { ods: 14, color: "#0A97D9" },
        { ods: 15, color: "#56C02B" },
        { ods: 16, color: "#00689D" },
        { ods: 17, color: "#19486A" },
      ],
      chartBase64: "",
      base: "",
      chartEsgBase64: "",
      baseEsg: "",
      esgLabels: ["Ambiental", "Governança", "Social"],
      esgIcons: ["esg-ambiente", "esg-governanca", "esg-social"],
      esgColors: ["#00B7BB", "#6A11CA", "#E65F0C"],
    };
  },
  mounted() {
    this.ods_onu_links = require("../json/ods_links.json");

    if (this.showData) {
      setTimeout(() => {
        this.loadChart();
      }, 100);

      setTimeout(() => {
        this.loadEsgChart();
      }, 200);
    }
  },
  methods: {
    async loadChart() {
      const labelImage = this.colors.map((color) => {
        const odsNumber = this.ods_number_colors.find(
          (ods) => ods.color === color
        ).ods;

        const baseUrl = process.env.VUE_APP_FRONTEND;

        const src = baseUrl + require(`./../images/ods-icon${odsNumber}.svg`);

        return {
          src: src,
          width: 60,
          height: 60,
        };
      });

      for (const image of labelImage) {
        const data = await fetch(image);
        const blob = await data.blob();

        const handler = (resolve) => {
          const file = new FileReader();
          file.readAsDataURL(blob);

          file.onloadend = () => {
            image.src = file.result;
            resolve();
          };
        };

        await new Promise(handler);
      }

      const self = this;

      setTimeout(() => {
        self.chart = new Chart(document.getElementsByClassName("chart2"), {
          type: "doughnut",
          data: {
            datasets: [
              {
                data: this.series,
                fill: false,
                backgroundColor: this.colors,
                borderColor: this.colors,
              },
            ],
          },
          options: {
            plugins: {
              datalabels: {
                anchor: "end",
                align: "start",
              },
              labels: {
                render: "image",
                textMargin: -60,
                images: labelImage,
              },
            },
            tooltips: { enabled: false },
            legend: { display: false },
            responsive: true,
            animation: {
              onComplete: () => {
                setTimeout(() => {
                  self.generateGraphBase64();
                }, 1000);
              },
            },
          },
        });
      }, 100);
    },
    async loadEsgChart() {
      setTimeout(() => {
        this.chart_esg = new Chart(document.getElementsByClassName("chart3"), {
          type: "doughnut",
          data: {
            datasets: [
              {
                data: this.esgData,
                fill: false,
                backgroundColor: this.esgColors,
                borderColor: this.esgColors,
              },
            ],
          },
          options: {
            plugins: {
              datalabels: {
                anchor: "end",
                align: "start",
              },
              labels: {
                render: "image",
                textMargin: -60
              },
            },
            tooltips: { enabled: false },
            legend: { display: false },
            responsive: true,
            animation: {
              onComplete: () => {
                setTimeout(() => {
                  this.generateEsgGraphBase64();
                }, 1000);
              },
            },
          },
        });
      }, 500);
    },
    getImageBaseUrl(url) {
      return process.env.VUE_APP_FRONTEND_S3_ASSETS + "email/" + url;
    },
    generateGraphBase64() {
      const base = this.chart.toBase64Image();
      this.base = base;
      this.$emit("chartBase64Image", base);
    },
    generateEsgGraphBase64() {
      const base = this.chart_esg.toBase64Image();
      this.baseEsg = base;
      this.$emit("chartEsgBase64Image", base);
    },
    getUrlChartImage(input) {
      return `${
        process.env.VUE_APP_FRONTEND_S3_MAP_SHARED_EMAIL
      }${this.organizacao.id.toString()}${input}.png`;
    },
  },
};
</script>

<style lang="scss"></style>